@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&family=Fira+Mono&family=Noto+Sans+SC:wght@300&display=swap');

@import '~bootstrap/scss/bootstrap';

@import 'utils';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-size: 0.9rem;
  background-color: $bg-color;
  margin: 0;
  padding: 0;
}

// #root {
//   width: 100vw;
//   height: 100vh;
// }

canvas {
  margin: 0px;
  padding: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  border: 'solid 1px red';
}

.canvasContainer {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: -1;
}
